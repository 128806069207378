import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './App.css';
import popSound from './estouro.mp3';
import mommo from './mommo.png';

function App() {
  const [bubbles, setBubbles] = useState([]);
  const [socialLinks, setSocialLinks] = useState({
    twitterLink: '#',
    telegramLink: '#',
    tokenCA: 'CA: Cr4k4bcGCeHH5n4jjo3ixSeVMvrWLWeYDaBL14kwpump',
  });
  const containerRef = useRef(null);
  const mousePos = useRef({ x: 0, y: 0, dx: 0, dy: 0 });

  useEffect(() => {
    // Faz a requisição para buscar os dados da API ao montar o componente
    axios
      .get('https://apitoreturnca.onrender.com/api/purchaseData')
      .then((response) => {
        const { twitterLink, telegramLink, tokenCA } = response.data;
        setSocialLinks({
          twitterLink,
          telegramLink,
          tokenCA,
        });
      })
      .catch((error) => {
        console.error('Erro ao buscar dados da API:', error);
      });

    // Criar bolhas iniciais
    const initialBubbles = Array.from({ length: 20 }, () => createBubble());
    setBubbles(initialBubbles);

    // Atualizar posição do mouse
    const handleMouseMove = (e) => {
      const prevX = mousePos.current.x;
      const prevY = mousePos.current.y;
      mousePos.current.x = e.clientX;
      mousePos.current.y = e.clientY;
      mousePos.current.dx = e.clientX - prevX;
      mousePos.current.dy = e.clientY - prevY;
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  useEffect(() => {
    // Loop de animação
    const interval = setInterval(() => {
      setBubbles((prevBubbles) =>
        prevBubbles.map((bubble, index, arr) => moveBubble(bubble, index, arr))
      );
    }, 20);

    return () => clearInterval(interval);
  }, []);

  const createBubble = () => {
    const size = 30 + Math.random() * 50;
    return {
      id: Math.random(),
      x: Math.random() * (window.innerWidth - size),
      y: Math.random() * (window.innerHeight - size),
      vx: (Math.random() - 0.5) * 1, // Velocidade inicial menor
      vy: (Math.random() - 0.5) * 1, // Velocidade inicial menor
      size,
      mass: size / 80, // Massa proporcional ao tamanho
    };
  };

  const moveBubble = (bubble, index, bubbles) => {
    let { x, y, vx, vy, size } = bubble;

    // Fricção para evitar aceleração excessiva
    const friction = 0.99;
    vx *= friction;
    vy *= friction;

    // Limitar velocidade máxima
    const maxSpeed = 2;
    const speed = Math.sqrt(vx * vx + vy * vy);
    if (speed > maxSpeed) {
      vx = (vx / speed) * maxSpeed;
      vy = (vy / speed) * maxSpeed;
    }

    // Influência do "vento" (movimento do mouse)
    const distX = x + size / 2 - mousePos.current.x;
    const distY = y + size / 2 - mousePos.current.y;
    const dist = Math.sqrt(distX * distX + distY * distY);

    if (dist < 150) {
      const windStrength = (150 - dist) / 150; // Força decrescente com a distância
      vx += mousePos.current.dx * 0.1 * windStrength;
      vy += mousePos.current.dy * 0.1 * windStrength;
    }

    // Atualizar posição
    x += vx;
    y += vy;

    // Detectar colisão com paredes
    if (x <= 0) {
      x = 0;
      vx *= -1;
    }
    if (x + size >= window.innerWidth) {
      x = window.innerWidth - size;
      vx *= -1;
    }
    if (y <= 0) {
      y = 0;
      vy *= -1;
    }
    if (y + size >= window.innerHeight) {
      y = window.innerHeight - size;
      vy *= -1;
    }

    let newBubble = { ...bubble, x, y, vx, vy };

    // Checar colisões com outras bolhas
    for (let i = index + 1; i < bubbles.length; i++) {
      const otherBubble = bubbles[i];
      if (checkCollision(newBubble, otherBubble)) {
        const [updatedBubble, updatedOtherBubble] = resolveCollision(newBubble, otherBubble);
        newBubble = updatedBubble;
        bubbles[i] = updatedOtherBubble;
      }
    }

    return newBubble;
  };

  const checkCollision = (bubble1, bubble2) => {
    const dx = bubble1.x + bubble1.size / 2 - (bubble2.x + bubble2.size / 2);
    const dy = bubble1.y + bubble1.size / 2 - (bubble2.y + bubble2.size / 2);
    const distance = Math.sqrt(dx * dx + dy * dy);
    return distance < (bubble1.size + bubble2.size) / 2;
  };

  const resolveCollision = (bubble1, bubble2) => {
    const xVelocityDiff = bubble1.vx - bubble2.vx;
    const yVelocityDiff = bubble1.vy - bubble2.vy;
    const xDist = bubble2.x - bubble1.x;
    const yDist = bubble2.y - bubble1.y;

    if (xVelocityDiff * xDist + yVelocityDiff * yDist >= 0) {
      const angle = -Math.atan2(bubble2.y - bubble1.y, bubble2.x - bubble1.x);
      const m1 = bubble1.mass;
      const m2 = bubble2.mass;

      const u1 = rotateVelocity({ x: bubble1.vx, y: bubble1.vy }, angle);
      const u2 = rotateVelocity({ x: bubble2.vx, y: bubble2.vy }, angle);

      const v1 = {
        x: (u1.x * (m1 - m2) + 2 * m2 * u2.x) / (m1 + m2),
        y: u1.y,
      };
      const v2 = {
        x: (u2.x * (m2 - m1) + 2 * m1 * u1.x) / (m1 + m2),
        y: u2.y,
      };

      const vFinal1 = rotateVelocity(v1, -angle);
      const vFinal2 = rotateVelocity(v2, -angle);

      const overlap = (bubble1.size + bubble2.size) / 2 - Math.hypot(xDist, yDist);
      const shiftX = overlap * Math.cos(angle) / 2;
      const shiftY = overlap * Math.sin(angle) / 2;

      bubble1.x -= shiftX;
      bubble1.y -= shiftY;
      bubble2.x += shiftX;
      bubble2.y += shiftY;

      bubble1.vx = vFinal1.x;
      bubble1.vy = vFinal1.y;
      bubble2.vx = vFinal2.x;
      bubble2.vy = vFinal2.y;
    }

    return [bubble1, bubble2];
  };

  const rotateVelocity = (velocity, angle) => {
    return {
      x: velocity.x * Math.cos(angle) - velocity.y * Math.sin(angle),
      y: velocity.x * Math.sin(angle) + velocity.y * Math.cos(angle),
    };
  };

  const handleBubbleClick = (id) => {
    setBubbles((prevBubbles) => prevBubbles.filter((b) => b.id !== id));
    const audio = new Audio(popSound);
    audio.play();
  };

  return (
    <div className="container" ref={containerRef}>
      {bubbles.map((bubble) => (
        <div
          key={bubble.id}
          className="bubble"
          style={{
            left: bubble.x,
            top: bubble.y,
            width: bubble.size,
            height: bubble.size,
          }}
          onClick={() => handleBubbleClick(bubble.id)}
        ></div>
      ))}

      <div className="page">
        <div className="infos">
          <img src={mommo} alt="Logo" />
          <div className="social">
            <a href={socialLinks.twitterLink} className="link x" target="_blank" rel="noopener noreferrer">X/Twitter</a>
            <a href={socialLinks.telegramLink} className="link t" target="_blank" rel="noopener noreferrer">Telegram</a>
          </div>
          <div className="tokenCa">
            CA: {socialLinks.tokenCA}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
